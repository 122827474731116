<template>
    <div>
        <v-card>
            <v-card-title>Lista de estudiantes</v-card-title>
           <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Apellidos y Nombres
                    </th>
                    <th class="text-left">
                        Nivel
                    </th>
                    <th class="text-left">
                        Grado
                    </th>
                    <th class="text-left">
                        Sección
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in students"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.nivel }}</td>
                    <td>{{ item.grado }}</td>
                    <td>{{ item.seccion }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </div>
</template>
<script>
export default {
    name:'TableStudents',
    props:['students'],
    data:()=>({
    }),
}
</script>