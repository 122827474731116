<template>
    <div>
        <v-card>
            <v-card-title>Crear nuevo usuario</v-card-title>
            <v-container>
                <v-row justify="space-around">
                    <v-col cols="10" md="3">
                        <v-select
                        return-object
                        :items="niveles"
                        item-text="descripcion"
                        item-value="id"
                        label="Seleciona el nivel"
                        chips
                        v-model="nivel_selected"
                        ></v-select>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-select
                        return-object
                        :items="grados"
                        item-text="descripcion"
                        item-value="id"
                        label="Seleciona el grado"
                        chips
                        v-model="grado_selected"
                        ></v-select>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-select
                        return-object
                        :items="secciones"
                        item-text="descripcion"
                        item-value="id"
                        label="Seleciona la sección"
                        chips
                        v-model="seccion_selected"
                        ></v-select>
                        
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="DNI"
                            v-model="dni"
                            @change="get_data_by_dni($event)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Nombres"
                            v-model="nombres"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Apellido paterno"
                            v-model="paterno"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Apellidos materno"
                            v-model="materno"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Correo institucional"
                            v-model="email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" md="3">
                        <v-text-field
                            label="Contraseña"
                            v-model="password"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="10" md="4">
                        <v-btn :disabled="!((enable_btn)&&(status_btn))" @click="post_user()"  
                        block
                        color="primary"
                        >Guardar datos</v-btn>
                    </v-col>
                     <v-col cols="10" md="4">
                        <v-btn :disabled="!(enable_watch_btn)" @click="get_students()"  
                        block
                        color="primary"
                        >Ver estudiantes</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card>
            <v-container>
                <v-row>
                    <v-col>
                        <table-students :students="estudiantes"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import axios from "axios";
import api from '@/api.config.js'
import TableStudents from './TableStudents'
export default {
    name:'FormCrearUsuario',
    components:{
        TableStudents
    },
    data:()=>({
        snackbar: false,
        text: '',
        timeout: 2500,
        niveles:[],
        grados:[],
        secciones:[],
        nivel_selected:null,
        grado_selected:null,
        seccion_selected:null,
        email:null,
        dni:null,
        password:null,
        nombres:'',
        paterno:'',
        materno:'',
        disable:true,
        status_btn:true,
        grupoid:1,
        estudiantes:[]
    }),
    computed:{
        enable_watch_btn(){
            if(this.nivel_selected&&
               this.grado_selected&&
               this.seccion_selected)
               {
                   return true;
               }
               else{
                   return false;
               }
        },
        enable_btn(){
            if(this.nivel_selected&&
               this.grado_selected&&
               this.seccion_selected&&
               this.nombres&&
               this.paterno&&
               this.materno&&
               this.email&&
               this.password&&
               this.dni
               )
               {
                   
                   return true;
               }
               else{
                   return false;
               }
        }
    },
    methods:{
        get_config(){
            let tok = JSON.parse(localStorage.getItem('token-user'));
            let token = tok['token-user']
            const config = {headers: {'Content-Type': 'application/json','Authorization':'Token '+token}}
            return config;
        },
        get_niveles(){   
            axios.get(api.API_PATH+'/api/nivel/',this.get_config()).then((response)=>{
                this.niveles = response.data;
            }).catch((e)=>{console.log(e)})
        },
        get_grados(){   
            axios.get(api.API_PATH+'/api/grado/',this.get_config()).then((response)=>{
                this.grados = response.data;
            }).catch((e)=>{console.log(e)})
        },
        get_secciones(){   
            axios.get(api.API_PATH+'/api/seccion/',this.get_config()).then((response)=>{
                this.secciones = response.data;
            }).catch((e)=>{console.log(e)})
        },
        post_user(){
            this.status_btn = false
            let dataUser = {
                        email:this.email.toLowerCase(),
                        user_name:this.email,
                        dni:this.dni,
                        is_student:true,
                        is_teacher:false,
                        is_staff:false,
                        is_active:true,
                        password:this.password
                        }
            axios.post(api.API_PATH+'/api/create-user/',dataUser,this.get_config()).then((response)=>{
                this.email = ''
                this.password = ''
                this.dni = ''
                let dataStudent = {
                    name: this.nombres,
                    paterno: this.paterno,
                    materno: this.materno,
                    user: response.data.id
                }
                console.log(dataStudent)
                axios.post(api.API_PATH+'/api/create-student/',dataStudent,this.get_config()).then((response)=>{
                    this.nombres = ''
                    this.paterno = ''
                    this.materno = ''
                    let idestudiante_post = response.data.id;
                    axios.get(api.API_PATH+'/api/id-grupo/'+this.nivel_selected.id+'/'+this.grado_selected.id+'/'+this.seccion_selected.id+'/',this.get_config())
                    .then((r)=>{
                        console.log(r)
                        let idgrupo_post = r.data[0].id;
                        this.post_matricula(idestudiante_post,idgrupo_post);
                    }).catch((e)=>{console.log(e)})
                }).catch((e)=>{
                    
                    console.log(e)
                    })

            }).catch((e)=>{
                alert("Error, posiblemente el usuario ya existe")
                console.log(e)
                })
        },
        post_matricula(idalumno,idgrupo){
            let data = {
                            alumno: idalumno,
                            grupo: idgrupo
                        }
            axios.post(api.API_PATH+'/api/matricular/',data,this.get_config()).then((response)=>{
                console.log(response)
                this.status_btn = true
                this.snackbar = true
                this.text = "Datos guardados con éxito."
                this.get_students()
            }).catch((e)=>{console.log(e)})
        },
        get_students(){
            this.estudiantes = []
            axios.get(api.API_PATH+'/api/id-grupo/'+this.nivel_selected.id+'/'+this.grado_selected.id+'/'+this.seccion_selected.id+'/',this.get_config())
                    .then((r)=>{
                        let idgrupo_post = r.data[0].id;
                        axios.get(api.API_PATH+'/api/listar-estudiantes-por-grupo/'+idgrupo_post+'/',this.get_config()).then((response)=>{
                        let arr = response.data
                        arr.forEach(e => {
                            this.estudiantes.push({
                                'name':e.alumno.paterno+' '+e.alumno.materno+' '+e.alumno.name,
                                'nivel':e.grupo.nivel.descripcion,
                                'grado':e.grupo.grado.descripcion,
                                'seccion':e.grupo.seccion.descripcion
                            })
                        });
                        this.estudiantes.sort(function(a,b){
                            if(a.name>b.name)
                            {
                                return 1;
                            }
                            if(a.name<b.name){
                                return -1;
                            }
                            return 0;
                        })
                    }).catch((e)=>{console.log(e)})
                    }).catch((e)=>{console.log(e)})
            
        },
        get_data_by_dni(value){
            this.password = 'cramer_'+this.dni
           axios.get('https://dni.optimizeperu.com/api/persons/'+value)
            .then((res)=>{
                this.nombres = res.data.name
                this.paterno = res.data.first_name
                this.materno = res.data.last_name
                this.password = 'cramer_'+this.dni
                this.email = '@cramerpuno.edu.pe'
            }).catch((e)=>{console.log(e)})
        }
    },
    mounted(){
        this.get_grados();
        this.get_niveles();
        this.get_secciones()
    }
}
</script>