<template>
    <div>
        <form-crear-usuario/>
        
    </div>
</template>
<script>
import FormCrearUsuario from '../components/users/FormCrearUsuario'
export default {
    components:{
        FormCrearUsuario,
    }
}
</script>